<template>
  <div>
    <div class="mb-2 flex justify-end">
      <div
          class="d-flex smallGap text-secondaryColor align-items-center"
          role="button"
          @click="$router.go(-1)"
      >
        <mdicon :width="25" :height="25" name="arrow-left-thick"/>
        <p class="mb-0" v-text="$t(locale.back)"/>
      </div>
    </div>
    <b-card>
      <div class="d-flex justify-content-between align-items-center">
        <b-card-title class="mb-0">
          {{ $t(locale.updateSegment) }}
        </b-card-title>
        <!-- <LittleTabs :tabs="tabs" :switchTabs="switchTabs" /> -->
      </div>
    </b-card>
    <!-- <ValidationObserver ref="form">
        <KeepAlive>
            <transition name="zoom-fade" mode="out-in">
                <component :is="currentComponent" />
            </transition>
        </KeepAlive>
    </ValidationObserver> -->

    <ValidationObserver ref="segmentForm">
      <div v-show="tabSwitch === 1">
        <b-row>
          <b-col cols="12" lg="12">
            <SecondaryCard :title="$t(locale.details)">
              <b-container fluid>
                <b-row>
                  <b-col cols="12" lg="8">
                    <b-row>
                      <b-col cols="12" lg="4">
                        <validation-provider
                            #default="{errors}"
                            :rules="'required'"
                            :name="$t(locale.name)"
                        >
                          <b-form-group
                              :label="$t(locale.name)"
                              label-for="input-1"
                          >
                            <b-form-input
                                size="sm"
                                v-model="singleSegment.name"
                                :placeholder="$t(locale.name)"
                            />
                            <small
                                class="text-danger"
                                v-text="errors[0]"
                            />
                          </b-form-group>
                        </validation-provider>
                      </b-col>

                      <b-col cols="12" lg="4">
                        <validation-provider
                            #default="{errors}"
                            :rules="'required'"
                            :name="$t(locale.description)"
                        >
                          <b-form-group
                              :label="$t(locale.description)"
                              label-for="input-1"
                          >
                            <b-form-input
                                size="sm"
                                v-model="singleSegment.description"
                                :placeholder="$t(locale.description)"
                            />
                            <small
                                class="text-danger"
                                v-text="errors[0]"
                            />
                          </b-form-group>
                        </validation-provider>
                      </b-col>

                      <!-- <b-col cols="12" lg="4">
                          <b-form-group
                                  :label="$t(locale.averageMonthly)"
                                  label-for="input-1"
                              >
                              <b-form-input
                                  size="sm"
                                  v-model="singleSegment.average_monthly_earnings"
                                  type="number"
                                  min="0"
                                  :placeholder="$t(locale.averageMonthly)"
                              />
                          </b-form-group>
                      </b-col> -->

                      <b-col cols="12" lg="4">
                        <b-form-group
                            :label="$t(locale.manufacturer)"
                            label-for="input-1"
                        >
                          <b-form-input
                              size="sm"
                              v-model="singleSegment.manufacturer"
                              :placeholder="$t(locale.manufacturer)"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" lg="8">
                        <b-row>
                          <b-col cols="12" lg="6">
                            <b-form-group
                                :label="$t(locale.supplier)"
                                label-for="input-1"
                            >
                              <v-select
                                  :placeholder="$t(locale.supplier)"
                                  :options="suppliers"
                                  v-model="singleSegment.supplier"
                                  @option:selected="computeSelect($event, 'supplier')"
                                  class="resource-selector select-size-sm d-inline-block w-full"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" lg="6">
                            <b-form-group
                                :label="$t(locale.inventoryLocation)"
                                label-for="input-1"
                            >
                              <b-form-input
                                  size="sm"
                                  v-model="singleSegment.inventory_location"
                                  :placeholder="$t(locale.inventoryLocation)"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" lg="6">
                            <b-form-group
                                :label="$t(locale.unit)"
                                label-for="input-1"
                            >
                              <v-select
                                  :placeholder="$t(locale.unit)"
                                  :options="units"
                                  v-model="singleSegment.unit"
                                  @option:selected="computeSelect($event, 'unit')"
                                  class="resource-selector select-size-sm d-inline-block w-full"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col cols="12" lg="4">
                        <b-row>
                          <b-col cols="12">
                            <div class="w-full">
                              <b-form-group
                                  :label="$t(locale.notes)"
                                  label-for="input-1"
                              >
                                <b-form-textarea
                                    v-model="singleSegment.notes"
                                    :placeholder="$t(locale.notes)"
                                />
                              </b-form-group>
                            </div>
                          </b-col>
                        </b-row>
                      </b-col>

                    </b-row>
                  </b-col>

                  <b-col cols="12" lg="4">
                    <b-row>
                      <b-col cols="12" lg="6">
                        <b-form-group
                            :label="$t(locale.stockItem)"
                            label-for="input-1"
                        >
                          <LittleTabs
                              :tabs="[
                                                        {
                                                            label: 'CustomersCardEdit.yes',
                                                            active: true,
                                                            value: true,
                                                        },
                                                        {
                                                            label: 'CustomersCardEdit.no',
                                                            active: false,
                                                            value: false,
                                                        },
                                                    ]"
                              v-model="singleSegment.stock_item"
                              :defaultValue="true"
                              buttonSize="sm"
                              containerStyle="justify-content-start"
                              btnClass="tabWrapperSmall2"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" lg="6">
                        <b-form-group
                            :label="$t(locale.discontinue)"
                            label-for="input-1"
                        >
                          <LittleTabs
                              :tabs="[
                                                        {
                                                            label: 'CustomersCardEdit.yes',
                                                            active: true,
                                                            value: true,
                                                        },
                                                        {
                                                            label: 'CustomersCardEdit.no',
                                                            active: false,
                                                            value: false,
                                                        },
                                                    ]"
                              v-model="singleSegment.discontinued"
                              :defaultValue="true"
                              buttonSize="sm"
                              containerStyle="justify-content-start"
                              btnClass="tabWrapperSmall2"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" lg="6">
                        <b-form-group
                            :label="$t(locale.externalWebshop)"
                            label-for="input-1"
                        >
                          <LittleTabs
                              :tabs="[
                                                        {
                                                            label: 'CustomersCardEdit.yes',
                                                            active: true,
                                                            value: true,
                                                        },
                                                        {
                                                            label: 'CustomersCardEdit.no',
                                                            active: false,
                                                            value: false,
                                                        },
                                                    ]"
                              v-model="singleSegment.external_webshop"
                              :defaultValue="true"
                              buttonSize="sm"
                              containerStyle="justify-content-start"
                              btnClass="tabWrapperSmall2"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" lg="6">
                        <b-form-group
                            :label="$t(locale.productType)"
                            label-for="input-1"
                        >
                          <LittleTabs
                              :tabs="[
                                                        {
                                                            label: 'ArticleSegment.form.stock',
                                                            active: true,
                                                            value: 'STOCK',
                                                        },
                                                        {
                                                            label: 'ArticleSegment.form.service',
                                                            active: false,
                                                            value: 'SERVICE',
                                                        },
                                                    ]"
                              v-model="singleSegment.product_type"
                              :defaultValue="true"
                              buttonSize="sm"
                              containerStyle="justify-content-start"
                              btnClass="tabWrapperSmall2"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" lg="6">
                        <b-form-group
                            :label="$t(locale.salesAccountExport)"
                            label-for="input-1"
                        >
                          <v-select class="w-full select-size-sm" v-model="singleSegment.sales_account_export"
                                    label="number" @option:selected="changeAccounts($event, 'sales_account_export')"
                                    :filterable="false" :options="accountResults" @search="searchAccounts">
                            <template slot="no-options">
                              Sök...
                            </template>
                            <template slot="option" slot-scope="option">
                              <div class="d-center">
                                {{ option.number }}
                              </div>
                            </template>
                            <template slot="selected-option" slot-scope="option">
                              <div class="selected d-center">
                                {{ option.number }}
                              </div>
                            </template>
                          </v-select>
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" lg="6">
                        <b-form-group
                            :label="$t(locale.salesAccountSE)"
                            label-for="input-1"
                        >
                          <v-select class="w-full select-size-sm" v-model="singleSegment.sales_account_se"
                                    label="number" @option:selected="changeAccounts($event, 'sales_account_se')"
                                    :filterable="false" :options="accountResults" @search="searchAccounts">
                            <template slot="no-options">
                              Sök...
                            </template>
                            <template slot="option" slot-scope="option">
                              <div class="d-center">
                                {{ option.number }}
                              </div>
                            </template>
                            <template slot="selected-option" slot-scope="option">
                              <div class="selected d-center">
                                {{ option.number }}
                              </div>
                            </template>
                          </v-select>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" lg="6">
                        <b-form-group
                            :label="$t(locale.purchaseAccount)"
                            label-for="input-1"
                        >
                          <v-select class="w-full select-size-sm" v-model="singleSegment.purchase_account"
                                    label="number" @option:selected="changeAccounts($event, 'purchase_account')"
                                    :filterable="false" :options="accountResults" @search="searchAccounts">
                            <template slot="no-options">
                              Sök...
                            </template>
                            <template slot="option" slot-scope="option">
                              <div class="d-center">
                                {{ option.number }}
                              </div>
                            </template>
                            <template slot="selected-option" slot-scope="option">
                              <div class="selected d-center">
                                {{ option.number }}
                              </div>
                            </template>
                          </v-select>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" lg="6">
                        <b-form-group
                            :label="$t(locale.salesAccountEUVat)"
                            label-for="input-1"
                        >
                          <v-select class="w-full select-size-sm" v-model="singleSegment.sales_account_eu_vat"
                                    label="number" @option:selected="changeAccounts($event, 'sales_account_eu_vat')"
                                    :filterable="false" :options="accountResults" @search="searchAccounts">
                            <template slot="no-options">
                              Sök...
                            </template>
                            <template slot="option" slot-scope="option">
                              <div class="d-center">
                                {{ option.number }}
                              </div>
                            </template>
                            <template slot="selected-option" slot-scope="option">
                              <div class="selected d-center">
                                {{ option.number }}
                              </div>
                            </template>
                          </v-select>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-container>
            </SecondaryCard>
          </b-col>

          <b-col cols="12" lg="12">
            <div class="shadow leftCardBottomBorder mt-2">
              <div class="flex justify-between w-100 bg-secondaryColor p-2 text-white leftCardTopBorder">
                <p
                    class="mb-0 h2 font-weight-bold"
                    style="color: inherit"
                    v-text="$t(locale.articles)"
                />
                <div class="flex gap-2">
                  <button
                      v-if="markArticles.length > 0"
                      @click="openRemoveCustomerModal({}, true)"
                      class="flex justify-center font-semibold text-white focus:outline-none rounded-md c-p-3 bg-red-500"
                      v-text="$t(locale.removeArticles)"
                  />
                  <button
                      class="flex justify-center font-semibold bg-white focus:outline-none rounded-md c-p-3 text-theme-sidebar"
                      v-text="$t(locale.addArticles)"
                      @click="tabSwitch = 2"
                  />
                </div>
              </div>

              <b-container class="p-1 bg-white rounded-bottom">
                <table class="table w-full z-50 relative mb-7">
                  <thead class="">
                  <tr>
                    <th class="text-left whitespace-nowrap p-2 md:p-0">
                                                <span class="text-xs text-gray-400">
                                                    <b-form-checkbox
                                                        v-model="selected"
                                                        @input="performAllChecked"
                                                        :value="checked"
                                                    />
                                                </span>
                    </th>
                    <th class="text-left whitespace-nowrap p-2 md:p-0"><span
                        class="text-xs text-gray-400">{{ $t(locale.articleNumber) }}</span></th>
                    <th class="text-left whitespace-nowrap p-2 md:p-0"><span
                        class="text-xs text-gray-400">{{ $t(locale.articleDesc) }}</span></th>
                    <th class="text-left whitespace-nowrap p-2 md:p-0"><span
                        class="text-xs text-gray-400">{{ $t(locale.purchasePrice) }}</span></th>
                    <th class="text-left whitespace-nowrap p-2 md:p-0"><span
                        class="text-xs text-gray-400">{{ $t(locale.articleType) }}</span></th>
                    <th class="text-left whitespace-nowrap p-2 md:p-0"><span
                        class="text-xs text-gray-400">{{ $t(locale.articleActive) }}</span></th>
                    <th class="text-left whitespace-nowrap p-2 md:p-0"><span class="text-xs text-gray-400">Action</span>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="article in articleResults" :key="article.uuid">
                    <td>
                                                <span>
                                                    <b-form-checkbox @change="performSingleChecked"
                                                                     v-model="article.checked"/>
                                                </span>
                    </td>
                    <td><span>{{ article.article_number }}</span></td>
                    <td><span>{{ article.description }}</span></td>
                    <td><span>{{ article.purchase_price }}</span></td>
                    <td><span>{{ article.type }}</span></td>
                    <td><span>{{ article.active }}</span></td>
                    <td>
                                                <span @click="openRemoveCustomerModal(article)">
                                                    <mdicon
                                                        class="text-danger"
                                                        role="button"
                                                        :width="20"
                                                        :height="20"
                                                        name="trash-can"
                                                    />    
                                                </span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-container>
            </div>
          </b-col>

        </b-row>
      </div>

      <div v-show="tabSwitch === 2">
        <b-row>
          <b-col cols="12" lg="12">
            <div class="shadow leftCardBottomBorder mt-2">
              <div class="flex justify-between w-100 bg-secondaryColor p-2 text-white leftCardTopBorder">
                <p
                    class="mb-0 h2 font-weight-bold"
                    style="color: inherit"
                    v-text="$t(locale.articles)"
                />
              </div>

              <b-container class="p-1 bg-white rounded-bottom">
                <div class="grid grid-cols-1 md:grid-cols-1 gap-3">
                  <div class="scrol-area table-responsive flex flex-col custom-border c-p-1 rounded">
                    <h3>Articles</h3>
                    <table class="table w-full z-50 relative mb-7">
                      <thead class="">
                      <tr>
                        <th class="text-left whitespace-nowrap p-2 md:p-0">
                                                        <span class="text-xs text-gray-400">
                                                            <b-form-checkbox
                                                                v-model="selected"
                                                                @input="performAllArticleChecked"
                                                                :value="checked"
                                                            />
                                                        </span>
                        </th>
                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span
                            class="text-xs text-gray-400">{{ $t(locale.articleNumber) }}</span></th>
                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span
                            class="text-xs text-gray-400">{{ $t(locale.description) }}</span></th>
                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span
                            class="text-xs text-gray-400">Segment</span></th>
                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span
                            class="text-xs text-gray-400">{{ $t(locale.salesPrice) }}</span></th>
                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span
                            class="text-xs text-gray-400">{{ $t(locale.purchasePrice) }}</span></th>
                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span
                            class="text-xs text-gray-400">{{ $t(locale.margin) }}</span></th>
                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span
                            class="text-xs text-gray-400">{{ $t(locale.discontinue) }}</span></th>
                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span
                            class="text-xs text-gray-400">{{ $t(locale.stockItem) }}</span></th>
                        <th class="text-left whitespace-nowrap p-2 md:p-0"><span
                            class="text-xs text-gray-400">{{ $t(locale.articleType) }}</span></th>
                      </tr>
                      </thead>
                      <tbody>

                      <tr v-for="item in articles.data" :key="item.uuid">
                        <td>
                                                        <span>
                                                            <b-form-checkbox
                                                                @change="toggleArticleSegment(item)"
                                                                v-model="item.checked"/>
                                                        </span>
                        </td>
                        <td><span>{{ item.article_number }}</span></td>
                        <td><span>{{ item.description }}</span></td>
                        <td><span v-if="item.segment">{{ item.segment.name }}</span></td>
                        <td><span v-if="item.sales_price">{{ item.sales_price }}</span></td>
                        <td><span v-if="item.purchase_price">{{ item.purchase_price }}</span></td>
                        <td><span v-if="item.purchase_price">{{
                            calculateMargin(item.sales_price, item.purchase_price)
                          }}</span></td>
                        <td><span v-if="item.deleted">{{ $t(locale.yes) }}</span> <span v-else>{{
                            $t(locale.no)
                          }}</span></td>
                        <td><span v-if="item.stock_goods">{{ $t(locale.yes) }}</span><span
                            v-else>{{ $t(locale.no) }}</span></td>
                        <td><span>{{ item.type }}</span></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </b-container>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-col cols="12" class="mt-5" v-if="tabSwitch === 2">
        <div class="flex flex-col">
          <div class="mb-3">
            <div class="w-full flex justify-end">
              <paginate
                  v-model="currentPage"
                  :page-count="articles.total_pages ? articles.total_pages : 1"
                  :click-handler="firePagination"
                  :prev-text="'<'"
                  :next-text="'>'"
                  :page-class="'page-item c-mr-1 border-unset'"
                  prev-link-class='page-link c-mr-1 border-unset'
                  next-link-class='page-link c-mr-1 border-unset'
                  next-class='next-item'
                  prev-class='prev-item'
                  page-link-class='page-link c-mr-1 border-unset'
                  active-class='active c-mr-1 border-unset'
                  :container-class="'pagination  b-pagination'">
              </paginate>
            </div>
          </div>
        </div>
        <b-row>
          <b-col cols="12" lg="8"></b-col>
          <b-col cols="12" lg="4">
            <b-row>
              <b-col cols="12" lg="6">
                <button
                    v-if="articlesChecked.length > 0 || markAllSegment.length > 0"
                    @click="openAddModal()"
                    class="flex whitespace-nowrap justify-center font-semibold text-white focus:outline-none rounded-md c-p-3 bg-theme-sidebar"
                    v-text="$t(locale.addArticles)"
                />
              </b-col>
              <b-col cols="12" lg="6">
                <b-button
                    @click="tabSwitch = 1"
                    block
                    variant="primary"
                    v-text="'Previous'"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>

      <b-col cols="12" v-if="tabSwitch === 1">
        <b-row align-h="between" class="smallGap mt-1">
          <b-col cols="12" lg="3">
            <b-button
                block
                variant="danger"
                @click="$bvModal.show('modal-remove-segment')"
                v-text="$t(locale.deleteSegment)"
            />
          </b-col>
          <b-col
              cols="12"
              lg="4"
              class="d-flex justify-content-between justify-lg-end"
          >
            <div class="">
              <b-button
                  block
                  variant="outline-danger"
                  @click="$router.go(-1)"
                  v-text="$t(locale.cancel)"
              />
            </div>
            <div class="">
              <b-button
                  @click="updateArticleSegment"
                  block
                  variant="primary"
                  v-text="processing ? $t('Message.loading') : $t(locale.editSegment)"
              />
            </div>
          </b-col>
        </b-row>
      </b-col>
    </ValidationObserver>

    <Modal
        id="modal-remove-segment"
        :hideFooter="true"
        :title="'Remove Customer Segment'"
    >
      <b-card-text
          class="d-flex flex-column text-center align-items-center justify-content-center"
          style="gap: 1rem"
      >
        <img
            src="@/assets/duplioAsset/icons/warningCircle.png"
            width="70px"
            height="70px"
        />
        <p class="mb-0 pb-0">
          Är du säker på att du vill radera artikelsegmentet?
        </p>
      </b-card-text>
      <div class="flex justify-end gap-3 mt-2">
        <b-button
            variant="outline-danger"
            size="md"
            @click="$bvModal.hide('modal-remove-segment')"
        >
                <span
                    v-text=" $t('Message.cancel')"/>
        </b-button>
        <b-button
            variant="primary"
            size="md"
            :disabled="sendingModalAction"
            @click="removeSegment"
        >
                <span
                    v-text="sendingModalAction ? $t('Message.loading') :  $t('Message.proceed')"
                />
        </b-button>
      </div>
    </Modal>

    <Modal
        id="modal-remove-article-segment"
        :hideFooter="true"
        :title="$t(locale.removeArticles)"
    >
      <b-card-text
          class="d-flex flex-column text-center align-items-center justify-content-center"
          style="gap: 1rem"
      >
        <img
            src="@/assets/duplioAsset/icons/warningCircle.png"
            width="70px"
            height="70px"
        />
        <p class="mb-0 pb-0" v-if="single">
          Är du säker på att du vill ta bort artikel med artikelnummer <b>{{ articleSelected.article_number }}</b>
          från detta segment?
        </p>
        <p class="mb-0 pb-0" v-if="bulk">
          The following will be removed; <br>
        <ul>
          <li v-for="item in markArticles" :key="item.uuid"> {{ $t(locale.articles) }} : <b>{{
              item.article_number
            }}</b></li>
        </ul>
        </p>
      </b-card-text>
      <div class="flex justify-end gap-3 mt-2">
        <b-button
            variant="outline-danger"
            size="md"
            @click="$bvModal.hide('modal-remove-article-segment')"
        >
                <span
                    v-text=" $t('Message.cancel')"/>
        </b-button>
        <b-button
            variant="primary"
            size="md"
            :disabled="sendingModalAction"
            @click="removeArticleFromSegment"
        >
                <span
                    v-text="sendingModalAction ? $t('Message.loading') :  $t('Message.proceed')"
                />
        </b-button>
      </div>
    </Modal>

    <Modal
        id="modal-add-article-segment"
        :hideFooter="true"
        :title="$t(locale.addArticles)"
    >
      <b-card-text
          class="d-flex flex-column"
          style="gap: 1rem"
      >
        <!-- <img
            src="@/assets/duplioAsset/icons/warningCircle.png"
            width="70px"
            height="70px"
        /> -->
        <p class="mb-0 pb-0">
          The following will be added to segments; <br>
        <ul>
          <li v-for="item in articlesChecked" :key="item.uuid"> {{ $t(locale.articles) }} : <b>{{
              item.article_number
            }}</b></li>
          <li v-for="item in markAllSegment" :key="item.uuid"> {{ $t(locale.articles) }} : <b>{{
              item.customer_number
            }}</b></li>
        </ul>
        </p>
      </b-card-text>
      <div class="flex justify-end gap-3 mt-2">
        <b-button
            variant="outline-danger"
            size="md"
            @click="$bvModal.hide('modal-add-article-segment')"
        >
                <span
                    v-text=" $t('Message.cancel')"/>
        </b-button>
        <b-button
            variant="primary"
            size="md"
            :disabled="sendingModalAction"
            @click="addArticlesToSegment"
        >
                <span
                    v-text="sendingModalAction ? $t('Message.loading') :  $t('Message.proceed')"
                />
        </b-button>
      </div>
    </Modal>
    <Modal
        id="modal-access-denied"
        :hideFooter="true"
        :no-close-on-backdrop="true"
        :hide-header="true"
        :hide-back-drop="true"
    >
      <access-denied/>
    </Modal>
  </div>
</template>

<script>
import basic from '@/@core/components/editCustomer/basic.vue';
import invoice from '@/@core/components/editCustomer/invoice.vue';
import vSelect from 'vue-select';
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import {getCountries} from './constants/countries'
import useAppConfig from '@core/app-config/useAppConfig';
import debounce from 'lodash.debounce';
import Paginate from 'vuejs-paginate'
import {mapGetters} from "vuex"
import accessDenied from "@core/components/permission/accessDenied";

export default {
  name: 'EditArticleSegment',
  components: {
    'paginate': Paginate,
    basic,
    invoice,
    ValidationObserver,
    ValidationProvider,
    vSelect, accessDenied
  },
  data() {
    return {
      config: useAppConfig(),
      tabSwitch: 1,
      singleSegment: {},
      sendingModalAction: false,
      processing: false,
      countries: [...getCountries()],
      selected: false,
      selectedSegment: false,
      checked: "selected",
      single: false,
      bulk: false,
      currentComponent: 'basic',
      tabs: [
        {
          label: 'CustomersCardEdit.basic',
          component: 'basic',
          active: true,
        },
        {
          label: 'CustomersCardEdit.invoice',
          component: 'invoice',
          active: false,
        },
      ],
      locale: {
        back: "articleSectionCard.backOverview",
        tabs: 'CustomersCard',

        delete: 'CustomersCardEdit.dltBtn',
        cancel: 'CustomersCardEdit.cancel',
        details: 'InvoiceCardCustomerEdit.details',

        averageMonthly: "ArticleSegment.form.averageMonthly",
        manufacturer: "ArticleSegment.form.manufacturer",
        supplier: "ArticleSegment.form.supplier",
        inventoryLocation: "ArticleSegment.form.inventoryLocation",
        unit: "ArticleSegment.form.unit",
        discontinue: "ArticleSegment.form.discontinue",
        stockItem: "ArticleSegment.form.stockItem",
        externalWebshop: "ArticleSegment.form.externalWebshop",
        productType: "ArticleSegment.form.productType",
        stock: "ArticleSegment.form.stock",
        service: "ArticleSegment.form.service",
        name: "CustomerSegment.name",
        description: "CustomerSegment.description",
        notes: "CustomerSegment.notes",
        purchaseAccount: "ArticleSegment.form.purchaseAccount",
        createSegment: "ArticleSegment.createSegment",
        deleteSegment: 'CustomerSegment.deleteSegment',

        articles: "ArticleSegment.Articles",
        articleNumber: "ArticleSegment.articleNumber",
        articleDesc: "ArticleSegment.articleDesc",
        purchasePrice: "ArticleSegment.purchasePrice",
        salesPrice: "ArticleSegment.salesPrice",
        margin: "ArticleSegment.margin",
        articleType: "ArticleSegment.articleType",
        articleActive: "ArticleSegment.articleActive",
        salesAccountEUVat: "ArticleSegment.salesAccountEUVat",
        salesAccountExport: "ArticleSegment.salesAccountExport",
        salesAccountSE: "ArticleSegment.salesAccountSE",
        updateSegment: "ArticleSegment.updateSegment",
        editSegment: "ArticleSegment.editSegment",
        addArticles: "ArticleSegment.addArticles",
        removeArticles: "ArticleSegment.removeArticles",
        yes: 'yes',
        no: 'no'

      },
      waysOfDelivery: [],
      deliveryTerms: [],
      termsOfPayment: [],
      printTemplates: [],
      priceLists: [],
      currencies: [],
      costCenter: [],
      projects: [],
      printTemplateInvoice: [],
      printTemplateOffer: [],
      printTemplateOrder: [],
      printTemplateCashInvoice: [],
      units: [],
      accountResults: [],
      suppliers: [],
      markArticles: [],
      markAllArticles: [],
      markAllSegment: [],
      articleResults: [],
      params: {},
      allArticles: {},
      segmentArticles: [],
      currentPage: 1,
      articleSelected: {},
      articlesChecked: [],
      articles: {
        data: [],
        total_pages: 1,
        total_resources: 1,
        pages: 1
      }
    }
  },
  computed: {
    message() {
      return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message';
    },
    features() {
      if (this.getLang == '') {
        return this.swedishLang ? 'features' : 'features';
      } else return this.getLang == 'sv' ? 'features' : 'features';
    },
    getLang() {
      return this.config.lang.value;
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  },
  methods: {
    calculateMargin(salesPrice, purchasePrice) {
      if (salesPrice && purchasePrice) {
        return parseFloat(salesPrice) - parseFloat(purchasePrice)
      }
      return 0
    },
    switchTabs(tabIndex) {
      this.tabs.find(item => item.active == true).active = false;

      this.currentComponent = this.tabs[tabIndex].component;
      this.tabSwitch = this.tabs[tabIndex].component === 'invoice' ? 2 : 1

      this.tabs[tabIndex].active = true;
    },
    getSingleSegment(uuid) {
      this.$useJwt.customSegment({URL: `/articles/${uuid}`, method: 'get'})
          .then(res => {
            this.singleSegment = res.data
            this.articleResults = this.singleSegment.articles.map(el => {
              return {
                ...el,
                checked: false
              }
            })
          }).catch(err => {
        if (err.response.status === 403) {
          window.Bus.$emit('trigger-access-denied',{'data': err.response.data})
        }
      })
    },
    openAccessDeniedModal() {
      this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', true)
      this.$bvModal.show("modal-access-denied");

    },
    articleSegment() {
      this.$useJwt.customSegment({URL: '/info/articles', method: 'get'})
          .then(res => {
            const {articles_not_in_segment, articles_in_segment} = res.data.data[0]
            // this.allArticles = {
            //   ...this.allArticles, data: articles_not_in_segment.map(el => {
            //     return {
            //       ...el,
            //       checked: false
            //     }
            //   })
            // }
            // const {id} = this.$route.params
            // this.segmentArticles = articles_in_segment.filter(el => el.segment.segment_uuid !== id)
            // this.segmentArticles.map(el => {
            //   return {
            //     ...el,
            //     checked: false
            //   }
            // })
          })
    },
    getArticles() {
      this.$useJwt.getArticles({params: this.params})
          .then(res => {
            res.data.data.map(item => {
              item.checked = false
            })
            this.articles = res.data

          })
    },
    getArticleSegment() {
      this.$useJwt.getArticlesSegment({URL: '', method: 'get'})
          .then(res => {
          })
    },
    async getAllRegisters() {
      const units = await this.$useJwt.getAllRegisters('units')
      const {data} = await this.$useJwt.getSuppliers()
      this.units = units.data.results.map(el => {
        return {label: el.description, value: el.code}
      })
      this.suppliers = data.results.map(el => {
        return {label: el.name, value: el.name}
      })
    },
    searchAccounts(search, loading) {
      if (search.length) {
        loading(true);
        this.searchRemoteAccounts(loading, search, this);
      }
    },
    searchRemoteAccounts: debounce(function (loading, search, vm) {
      this.$useJwt.getAllRegisters(`accounts?search=${escape(search)}`)
          .then(res => {
            this.accountResults = res.data.results
            loading(false);
          })
          .catch(err => {
          })
    }, 350),
    changeAccounts(account, key) {
      this.singleSegment[key] = account.number
    },
    removeSegment() {
      this.sendingModalAction = true
      this.$useJwt.customSegment({URL: `/articles/${this.singleSegment.uuid}`, method: 'delete'})
          .then(res => {
            this.sendingModalAction = false
            this.$bvModal.hide('modal-remove-segment')
            this.$router.replace({name: 'acountingArticle'})
            this.popupMsg(
                this.$t('Message.Success'),
                res.data[this.message],
                'CheckIcon',
                'success'
            );
          })
          .catch(err => {
            this.sendingModalAction = false
            if (err.response.data && err.response.data && err.response.data.message) {
              if (err.response.data.message.ErrorInformation) {
                this.popupMsg(
                    this.$t('Message.Failed'),
                    err.response.data.message.ErrorInformation.message,
                    'AlertTriangleIcon',
                    'danger'
                );
              } else {
                this.popupMsg(
                    this.$t('Message.Failed'),
                    err.response.data.message,
                    'AlertTriangleIcon',
                    'danger'
                );
              }
            } else {
              this.popupMsg(
                  this.$t('Message.Failed'),
                  err.response.data[this.message],
                  'AlertTriangleIcon',
                  'danger'
              );
            }
          })
    },
    openRemoveCustomerModal(item, bulk = false) {
      if (bulk) {
        this.single = false
        this.bulk = true
      } else {
        this.articleSelected = item
        this.single = true
        this.bulk = false
      }
      this.$bvModal.show('modal-remove-article-segment')
    },
    updateArticleSegment() {
      this.$refs.segmentForm.validate().then(res => {
        if (res) {
          this.processing = true
          const payload = this.singleSegment
          delete payload['club_account']
          delete payload['articles']
          this.$useJwt.customSegment({URL: `/articles/${this.singleSegment.uuid}`, method: 'put'}, payload)
              .then(res => {
                this.processing = false
                this.$router.push({name: 'acountingArticle'})
                this.popupMsg(
                    this.$t('Message.Success'),
                    res.data[this.message],
                    'CheckIcon',
                    'success'
                );
              })
              .catch(err => {
                this.processing = false
                if (err.response.data && err.response.data && err.response.data.message) {
                  if (err.response.data.message.ErrorInformation) {
                    this.popupMsg(
                        this.$t('Message.Failed'),
                        err.response.data.message.ErrorInformation.message,
                        'AlertTriangleIcon',
                        'danger'
                    );
                  } else {
                    this.popupMsg(
                        this.$t('Message.Failed'),
                        err.response.data.message,
                        'AlertTriangleIcon',
                        'danger'
                    );
                  }
                } else {
                  this.popupMsg(
                      this.$t('Message.Failed'),
                      err.response.data[this.message],
                      'AlertTriangleIcon',
                      'danger'
                  );
                }
              })
        }
      });
    },
    computeSelect(event, key) {
      this.singleSegment[key] = event.value
    },
    removeArticleFromSegment() {
      this.sendingModalAction = true
      const payload = {
        articles: []
      }
      if (this.single) {
        payload.articles.push(this.articleSelected.uuid)
      } else {
        const array = []
        this.markArticles.map(el => {
          array.push(el.uuid)
        })
        payload.articles = array
      }

      this.$useJwt.customSegment({URL: `/articles/${this.singleSegment.uuid}/remove_articles`, method: 'put'}, payload)
          .then(res => {
            this.getSingleSegment(this.$route.params.id)
            this.articleSegment()
            this.sendingModalAction = false
            this.bulk = false
            this.single = false
            this.markArticles = []
            this.$bvModal.hide('modal-remove-article-segment')
            this.popupMsg(
                this.$t('Message.Success'),
                res.data[this.message],
                'CheckIcon',
                'success'
            );
          })
          .catch(err => {
            this.sendingModalAction = false
            if (err.response.data && err.response.data && err.response.data.message) {
              if (err.response.data.message.ErrorInformation) {
                this.popupMsg(
                    this.$t('Message.Failed'),
                    err.response.data.message.ErrorInformation.message,
                    'AlertTriangleIcon',
                    'danger'
                );
              } else {
                this.popupMsg(
                    this.$t('Message.Failed'),
                    err.response.data.message,
                    'AlertTriangleIcon',
                    'danger'
                );
              }
            } else {
              this.popupMsg(
                  this.$t('Message.Failed'),
                  err.response.data[this.message],
                  'AlertTriangleIcon',
                  'danger'
              );
            }
          })
    },
    addArticlesToSegment() {
      this.sendingModalAction = true
      const payload = {
        articles: []
      }
      const array = []
      this.articlesChecked.map(el => {
        array.push(el.uuid)
      })
      this.markAllSegment.map(el => {
        array.push(el.uuid)
      })
      payload.articles = array

      this.$useJwt.customSegment({URL: `/articles/${this.singleSegment.uuid}/add_articles`, method: 'put'}, payload)
          .then(res => {
            this.sendingModalAction = false
            this.markAllArticles = []
            this.markAllSegment = []
            this.$bvModal.hide('modal-add-article-segment')
            this.tabSwitch = 1
            this.getAllRegisters()
            this.getSingleSegment(this.$route.params.id)
            this.articleSegment()
            this.popupMsg(
                this.$t('Message.Success'),
                res.data[this.message],
                'CheckIcon',
                'success'
            );
          })
          .catch(err => {
            this.sendingModalAction = false
            if (err.response.data && err.response.data && err.response.data.message) {
              if (err.response.data.message.ErrorInformation) {
                this.popupMsg(
                    this.$t('Message.Failed'),
                    err.response.data.message.ErrorInformation.message,
                    'AlertTriangleIcon',
                    'danger'
                );
              } else {
                this.popupMsg(
                    this.$t('Message.Failed'),
                    err.response.data.message,
                    'AlertTriangleIcon',
                    'danger'
                );
              }
            } else {
              this.popupMsg(
                  this.$t('Message.Failed'),
                  err.response.data[this.message],
                  'AlertTriangleIcon',
                  'danger'
              );
            }
          })
    },
    performAllChecked(e) {
      this.articleResults.forEach(element => {
        element.checked = this.selected === 'selected' ? true : false
      })

      const filter = this.articleResults.filter(item => item.checked)
      if (filter.length > 0) {
        this.markArticles = filter
      } else {
        this.markArticles = []
      }
    },
    performSingleChecked(e) {
      const filter = this.articleResults.filter(item => item.checked)
      if (filter.length > 0) {
        this.markArticles = filter
      } else {
        this.markArticles = []
      }
    },
    performAllArticleChecked(e) {
      this.allArticles.data.forEach(element => {
        element.checked = this.selected === 'selected' ? true : false
      })
      this.performAllSingleChecked(e)
    },
    performAllSegmentChecked(e) {
      this.segmentArticles.forEach(element => {
        element.checked = this.selectedSegment === 'selected' ? true : false
      })
      this.performAllSingleSegmentChecked(e)
    },
    performAllSingleChecked(e, mode) {
      const filter = this.allArticles.data.filter(item => item.checked)
      if (filter.length > 0) {
        this.markAllArticles = filter
      } else {
        this.markAllArticles = []
      }
    },
    toggleArticleSegment(article) {
      const filterIndex = this.articlesChecked.findIndex(item => item.uuid === article.uuid)
      if (filterIndex < 0) {
        this.articlesChecked.push(article)
      } else {
        this.articlesChecked.splice(filterIndex, 1)
      }
    },
    performAllSingleSegmentChecked(e) {
      const filter = this.segmentArticles.filter(item => item.checked)
      if (filter.length > 0) {
        this.markAllSegment = filter
      } else {
        this.markAllSegment = []
      }
    },
    firePagination(number) {
      this.currentPage = number
      this.params.page = number;
      this.getArticles();
    },
    openAddModal() {
      this.$bvModal.show('modal-add-article-segment')
    }
  },

  async mounted() {
    // this.getArticles()
    this.getAllRegisters()
    const {id} = this.$route.params
    const {redirect} = this.$route.query
    if (id) {
      if (redirect) {
        this.tabSwitch = 2
      }
      await this.getSingleSegment(id)
      await this.getArticles()
      await this.articleSegment()
    }
    this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', false)
  },
};
</script>

<style lang='scss'>
@import '@core/scss/vue/libs/vue-select.scss';

.table th {
  padding: .4rem !important;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table tbody, td {
  padding: .4rem !important;
  // padding-bottom: .4rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6 !important;
  border-bottom: 1px solid #dee2e6 !important;
}
</style>
